import { Container } from 'src/components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons'

export function MobileBanner() {
  return (
    <section
      id="mobile"
      className="relative overflow-hidden bg-slate-900 py-24 xl:py-48"
    >
      <Container className="relative">
        <div className="relative z-20 mx-auto text-left">
          <div className="lg:max-w-2xl xl:max-w-3xl">
            <h2 className="font-display text-4xl tracking-tight text-white">
              Mobilna aplikacja jeździecka
            </h2>
            <div className="my-8 text-slate-200 text-left grid grid-cols-1 gap-y-2 text-lg">
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> Bądź zawsze na bieżąco z wydarzeniami w stajni!
              </div>
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> Nie przegap żadnego treningu dzięki powiadomieniom Push.
              </div>
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> Zarządzaj treningami w prosty sposób, w dowolnym miejscu.
              </div>
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> Dostęp do Twojego konta w każdej chwili zawsze pod ręką.
              </div>
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> Terminarz dla jeźdźców i instruktorów.
              </div>
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> Nawiąż kontakt z jeźdźcami.
              </div>
            </div>
            <p className="mt-8 text-slate-300 text-xl">
              Skorzystaj z naszej aplikacji mobilnej, aby zawsze mieć dostęp do
              swojego konta. Zarządzaj swoimi treningami z dowolnego miejsca. Prowadzenie
              ośrodka jeździeckiego nigdy nie było tak proste.
            </p>
          </div>
          <div className="text-center md:text-start text-xl lg:text-2xl xl:text-3xl">
            <p className="mt-8 tracking-tight text-secondary-500">
              Dostępna w sklepie App Store i Google Play.
            </p>
            <div className="flex gap-5 my-5 justify-center lg:justify-normal">
              <div>
                <a href="https://apps.apple.com/pl/app/redini-aplikacja-je%C5%BAdziecka/id6737122460" target="_blank" rel="noreferrer">
                  <img className="h-16 w-auto" style={{ margin: '1px' }} src="/images/mobile/app-store-pl.webp" alt="Pobierz w App Store" title="Pobierz w App Store" />
                </a>
              </div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.h0pe.rediniapp" target="_blank" rel="noreferrer">
                  <img className="h-16 w-auto" src="/images/mobile/google-play-pl.webp" alt="Pobierz z Google Play" title="Pobierz z Google Play" />
                </a>
              </div>
            </div>
            <div className="flex flex-col mt-8">
              <p className="text-sm text-slate-500">
                Mobilna aplikacja jeździecka dostępna na urządzeniach z systemem Android w wersjach 6.0 i wyższe.
              </p>
              <p className="text-sm text-slate-500">
                Mobilna aplikacja jeździecka dostępna na urządzeniach z systemem iOS w wersjach 13.4 i wyższe.
              </p>
            </div>
          </div>
        </div>
      </Container>
      <div className="hidden lg:block absolute z-10 top-1/4 right-24 xl:right-32 2xl:right-48 w-1/4 max-w-96">
        <img src='/images/mobile/phones.webp' />
      </div>
    </section>
  )
}
